/* eslint-disable complexity */
// import request from './request';
import { isNil } from 'lodash-es';
import { TrpcBase } from '../../../utils/trpc';
import useUploadHook from './useUploadHook';
import { Image } from 'antd';

export default function useAttachmentHook(
	uuid: string,
	isGetUrl = false,
	uploadFinishedCallback?: (uploadedPath: string) => void,
	height?: number,
) {
	const SaveAttachment = TrpcBase.apartment.saveAttachment.useMutation();
	const getAttachmentUrl = TrpcBase.apartment.getAttachmentUrl.useQuery(uuid, {
		enabled: !isNil(uuid) && isGetUrl,
	});

	const { uploadFileAction, getDom } = useUploadHook({
		uploadFinishedCallback: async (uploadedPath) => {
			await SaveAttachment.mutateAsync({
				uuid: uuid,
				attachmentUrl: uploadedPath,
			});
			await getAttachmentUrl.refetch();
			await uploadFinishedCallback?.(uploadedPath);
		},
	});

	return {
		getAttachmentUrl,
		uploadFileAction,
		Dom: getDom(
			SaveAttachment.isLoading ? '正在上传' : '上传单据',
			`apartment_attachment/${uuid}`,
		),
		previewDom: getAttachmentUrl?.data && (
			<Image height={height} src={getAttachmentUrl?.data} />
		),
	};
}
